<template>
  <section
    v-if="bestsellerPublications.length > 0"
    id="index-bestseller-publications"
    class="relative lg:pt-16 lg:pb-12 lg:py-24 lg:px-24 xl:px-32 content-center items-center justify-center"
  >
    <div
      class="lg:container"
    >
      <div
        v-for="bestsellerCategory in bestsellerPublications"
        :key="bestsellerCategory.label"
        class="py-5 lg:pb-0"
      >
        <div 
          v-if="bestsellerCategory.data.length > 0"
          class="post-loop-wrapper grid gap-5 lg:gap-10 grid-cols-12 lg:mt-12 xl:mt-24 px-4 md:px-0"
        >
          <div
            data-aos="fade-in"
            class="h-100 col-span-12 sm:col-span-6 md:col-span-8 lg:col-span-8 xl:col-span-6 2xl:col-span-6"
          >
            <div
              class="transition-all lazy relative shadow-md group-hover:shadow-2xl h-full w-full rounded-md background"
              :data-bg="getImage(bestsellerCategory.label)"
              :data-bg-hidpi="getImage(bestsellerCategory.label)"
            >
              <h4 class="text-left absolute bottom-0 bg-white right-0 uppercase text-l pb-3 pt-5 px-6 font-semibold text-primary">
                {{ $t('BESTSELLERS') }}: {{ $t(bestsellerCategory.label) }}
              </h4>
            </div>
          </div>
          <div 
            v-for="(publication, index) in bestsellerCategory.data"
            :key="index"
            class="h-100 col-start-2 md:col-start-0 col-span-10 sm:col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-4 2xl:col-span-3"
          >
            <api-card
              :slug="publication.slug"
              :mode="'publications/'"
              :class="'h-100'"
              :item="publication"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { defineAsyncComponent } from "vue";
const ApiCard = defineAsyncComponent(() =>
  import("../../../../../FrontendBundle/assets/js/components/common/ItemList/ApiCard" /* webpackChunkName: "api-card" */)
);

export default {
  name: "IndexBestsellerPublications",
  components: {
    ApiCard
  },
  data() {
    return {
      bestsellerPublications: []
    };
  },
  mounted() {
    axios.get(process.env.apiURL + '/fe/api/publication/bestseller')
      .then(response =>  {
        this.bestsellerPublications = response.data;
      })
      .catch(function (error) {
      })
      .then(function () {
      });
  },
  methods: {
    getTeaser(teaser) {
      return this.truncate(teaser, 144, '</p>');
    },
    getImage(label) {
      return 'https://storysh.de/site/img/' + label + '.webp';
    },
    truncate: function (text, length, suffix) {
      if (typeof text === 'undefined') {
        return '';
      }
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    getImageSrc(item, viewport) {
      if (typeof item.images === "undefined") {
        return item.image;
      }

      if (item.images.length === 0) {
        return item.image;
      }

      let imageCount = item.images.length,
        count = 0,
        hasMain = false;

      if (imageCount === 1) {
        if (item.images[0].media.thumbnails.length === 0) {
          return item.images[0];
        }
        return item.images[0].media.thumbnails[viewport]['list'].img;
      }

      for (count; count <= imageCount - 1; count++) {
        if (item.images[count].main === true) {
          hasMain = true;
          return item.images[count].media.thumbnails[viewport]['list'].img;
        }

        if (count === imageCount - 1 && hasMain === false) {
          return item.images[0].media.thumbnails[viewport]['list'].img;
        }
      }
    },
  }
}
</script>